import moment from 'moment';
import { CURRENT_MONTH, Q1, Q2, Q3, Q4, YEAR, YEAR_1 } from './transactionsFilterManager';
import { finicityAccountType, insuranceColors } from "../config/constant";
import { jwtDecode } from "jwt-decode";

export const getDefaultDate = () => {
  const year_1 = moment().subtract(1, 'year').toDate();
  const today = moment().toDate();
  return [year_1, today];
};

export const numberFormat = (num) => {
  let value = 0;
  if (!num) {
    return value;
  } else {
    value = Number(num);
  }
  return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const epochToDate = (epoch, format = 'YYYY-MM-DD') => {
  return moment(epoch * 1000).format(format);
}

export const getPeriodDates = (period) => {
  let date = moment();
  let startDate = null;
  let endDate = null;
  switch (period) {
    case CURRENT_MONTH: {
      startDate = date.startOf('month').toDate();
      endDate = date.endOf('month').toDate();
      break;
    }
    case Q1:
    case Q2:
    case Q3:
    case Q4: {
      const quarter = period[1];
      startDate = date.quarter(quarter)
        .startOf('quarter').toDate();
      endDate = date.quarter(quarter)
        .endOf('quarter').toDate();
      break;
    }
    case YEAR: {
      startDate = moment().startOf('year').toDate();
      endDate = moment().toDate();
      break;
    }
    case YEAR_1:
    default: {
      startDate = moment().subtract(1, 'year').toDate();
      endDate = moment().toDate();
      break;
    }
  }
  return [startDate, endDate];
}

export function getColorByFirstChar(name) {
  const firstChar = name.charAt(0).toUpperCase();
  // Using the charCodeAt() function to get the Unicode value and considering only alphabets and digits
  const charCode = firstChar.charCodeAt(0);
  const index = (charCode >= 48 && charCode <= 57) ? charCode - 48 : (charCode >= 65 && charCode <= 90) ? charCode - 65 : -1;

  return index >= 0 ? insuranceColors[index % insuranceColors.length] : '#000000'; // Default color (black) if index is -1
}

export function formatInsuranceProviderName(str) {
  // Use a regular expression to split the input into words
  const words = str.match(/[A-Za-z][a-z]*/g);

  // Capitalize the first letter of each word and apply the rules
  const capitalizedWords = words.map(word => {
    if (word.length < 6) {
      return word.toUpperCase();
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  // Join the words with space
  return capitalizedWords.join(' ');
}

export function formatPhoneNumber(phoneNumberString) {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export function convertFriendlyName(type) {
  console.log(type)
  if (!type) return '';
  return type
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function isValidEmail(email) {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex.test(email);
}

export function isValidUSPhone(phone) {
  const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return regex.test(phone);
}

export function removePlusPhoneNumber(phone) {
  const phoneNumberWithoutCountryCode = phone.startsWith('+1') ? phone.replace('+1', '') : phone;
  return phoneNumberWithoutCountryCode.replace(/\D/g, ''); // If you want this function to return the new value
}

export const getValueByFieldName = (fieldValues, fieldName) => {
  const field = fieldValues.find((field) => field.fieldName === fieldName)
  return field?.value || ''
}

export function generateRandomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getPlanPrice(plan) {
  switch (plan) {
    // smartfinance™ plans
    case 'SMARTFINANCE_LITE_MONTHLY':
      return 400;
    case 'SMARTFINANCE_PRO_MONTHLY':
      return 700;
    case 'SMARTFINANCE_ELITE_MONTHLY':
      return 1250;

    // smarttax™ plans
    case 'SMARTTAX_LITE_MONTHLY':
      return 1250;
    case 'SMARTTAX_PRO_MONTHLY':
      return 2200;
    case 'SMARTTAX_ELITE_MONTHLY':
      return 5000;

    default:
      return 0; // Default if plan is unknown
  }
}

export function getPlanName(plan) {
  switch (plan) {
    // smartfinance™ plans
    case 'SMARTFINANCE_LITE_MONTHLY':
      return 'Lite';
    case 'SMARTFINANCE_PRO_MONTHLY':
      return 'Pro';
    case 'SMARTFINANCE_ELITE_MONTHLY':
      return 'Elite';

    // smarttax™ plans
    case 'SMARTTAX_LITE_MONTHLY':
      return 'Lite';
    case 'SMARTTAX_PRO_MONTHLY':
      return 'Pro';
    case 'SMARTTAX_ELITE_MONTHLY':
      return 'Elite';
    default:
      return 'Unknown Plan';
  }
}

export function getAvailableCredit(plan) {
  switch (plan) {
    // smartfinance™ plans
    case 'SMARTFINANCE_LITE_MONTHLY':
      return 10; // Example credit limit
    case 'SMARTFINANCE_PRO_MONTHLY':
      return 20; // Example credit limit
    case 'SMARTFINANCE_ELITE_MONTHLY':
      return 50; // Example credit limit

    // smarttax™ plans
    case 'SMARTTAX_LITE_MONTHLY':
      return 10; // Example credit limit
    case 'SMARTTAX_PRO_MONTHLY':
      return 20; // Example credit limit
    case 'SMARTTAX_ELITE_MONTHLY':
      return 50; // Example credit limit

    default:
      return 0; // Default if plan is unknown
  }
}


export function getAdditionalCreditCost(plan) {
  switch (plan) {
    // smartfinance™ plans
    case 'SMARTFINANCE_LITE_MONTHLY':
      return 35;
    case 'SMARTFINANCE_PRO_MONTHLY':
      return 25;
    case 'SMARTFINANCE_ELITE_MONTHLY':
      return 20;

    // smarttax™ plans
    case 'SMARTTAX_LITE_MONTHLY':
      return 125;
    case 'SMARTTAX_PRO_MONTHLY':
      return 110;
    case 'SMARTTAX_ELITE_MONTHLY':
      return 100;

    default:
      return 0; // Default if plan is unknown
  }
}

export function countDecimals(number) {
  if (number === null) {
    return 0;
  } else {
    const numberString = number.toString();
    if (numberString.includes('.')) {
      return numberString.split('.')[1].length;
    }
  }
}

export const currencyFormat = (num) => {
  let value;
  if (!num) {
    value = 0;
  } else if (typeof num === 'number') {
    value = num;
  } else {
    value = Number(num);
  }
  return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const getAccountTypeInfo = (accountType) => {
  const info = finicityAccountType[accountType];
  if (info) {
    return `${info.category} ${info.displayName}`;
  }
  return "Unknown";
};

export const validateRole = (token) => {
  try {
    const decoded = jwtDecode(token);

    if (decoded.role) {
      return decoded.role;
    } else {
      return 'invalid'
    }
  } catch (err) {
    console.error('Invalid token or token expired', err);
    return 'invalid';
  }
}

export const getBadgeColor = (status) => {
  switch (status) {
    case 'COMPLETED':
      return 'green';
    case 'ACTIVE':
      return 'green';
    case 'INACTIVE':
      return 'blue';
    case 'REJECTED':
      return 'orange';
    case 'CLOSED':
      return 'red';
    case 'INVITE SENT':
      return 'orange';
    default:
      return 'blue';
  }
};

export const smartfinancePlans = [
  {
    name: 'Lite',
    price: '$400/Firm',
    credits: '10 credits',
    additionalCost: '$35/Each',
    planType: 'SMARTFINANCE_LITE_MONTHLY'
  },
  {
    name: 'Pro',
    price: '$700/Firm',
    credits: '20 credits',
    additionalCost: '$25/Each',
    planType: 'SMARTFINANCE_PRO_MONTHLY'
  },
  {
    name: 'Elite',
    price: '$1,250/Firm',
    credits: '50 credits',
    additionalCost: '$20/Each',
    planType: 'SMARTFINANCE_ELITE_MONTHLY'
  }
];

export const smarttaxPlans = [
  {
    name: 'Lite',
    price: '$1,250/Firm',
    credits: '10 credits',
    additionalCost: '$125/Order',
    planType: 'SMARTTAX_LITE_MONTHLY'
  },
  {
    name: 'Pro',
    price: '$2,200/Firm',
    credits: '20 credits',
    additionalCost: '$110/Order',
    planType: 'SMARTTAX_PRO_MONTHLY'
  },
  {
    name: 'Elite',
    price: '$5,000/Firm',
    credits: '50 credits',
    additionalCost: '$100/Order',
    planType: 'SMARTTAX_ELITE_MONTHLY'
  }
];

export function calculateAvailableCreditsAndCharges(plan, usedCredits) {
  const availableCredits = getAvailableCredit(plan);
  let remainingCredits = availableCredits - (usedCredits || 0);
  let charges = 0;

  if (remainingCredits < 0) {
    charges = Math.ceil(Math.abs(remainingCredits)) * getAdditionalCreditCost(plan);
  }

  return {
    remainingCredits,
    charges,
  };
}