import apiRequest from './api';
import { BASE_API_URL } from '../config/constant';

export const getPossibleSecurityNames = (clientId: number, filter: any) => {
  return apiRequest('post', `${BASE_API_URL}/securities/security-name-possible/as-advisor`, {
    body: {
      clientId,
      filter
    }
  }).then(res => res.data);
};

export const symbolLookUp = (name: string = '') => {
  return apiRequest('get', `${BASE_API_URL}/securities/search`, {
    queryStringParameters: {
      name
    }
  }).then(res => res.data);
};

export const createOrUpdateSecurityByAdvisor = (clientId: number, accountId: string, symbol: string, name: string, securityId?: string) => {
  return apiRequest('post', `${BASE_API_URL}/securities/security/as-advisor`, {
    body: {
      clientId,
      accountId,
      symbol,
      name,
      securityId, // Update if securityId provided.
    }
  }).then(res => res.data);
};

export const downloadSecuritiesByAdvisor = (clientId: number, accountId: string) => {
  return apiRequest('post', `${BASE_API_URL}/securities/export/as-advisor`, {
    body: {
      clientId,
      accountId,
    }
  }).then(res => res.data);
};

export const getAccountHoldings = (clientId: number) => {
  return apiRequest('post', `${BASE_API_URL}/securities/security-holdings/as-advisor`, {
    body: {
      clientId,
    }
  }).then(res => res.data);
};

export const getPriceRangeData = (clientId: number, securityId: string, start: string, end: string) => {
  return apiRequest('post', `${BASE_API_URL}/securities/market-data/price-range/as-advisor`, {
    body: {
      clientId,
      securityId,
      start,
      end,
    }
  }).then(res => res.data);
};

export const deleteSecurityByAdvisor = (clientId: number, securityId: string) => apiRequest('del', `${BASE_API_URL}/securities/security/${securityId}/as-advisor`, {
  body: {
    clientId,
  }
});

export const downloadProspectusFile = (ticker: string) => {
  return apiRequest('get', `${BASE_API_URL}/securities/prospectus/download/as-advisor`, {
    queryStringParameters: {
      ticker
    }
  }).then(res => res.data);
};

export const getSmartPortfolioInfo = (clientId: number, accountId: string) => apiRequest('post', `${BASE_API_URL}/securities/smart-portfolio`, {
  body: {
    clientId,
    accountId
  }
}).then(res => res.data);