import {
  Avatar,
  Button,
  Flex,
  Icon,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable, } from 'react-table';
import { numberFormat } from '../../../../libraries/utils';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import SmartPortfolioModal from "../../modals/components/SmartPortfolioModal";

const tableColumnsTopCreators = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Portfolio',
    accessor: 'portfolio',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
];


function AccountBalance(props) {
  const {balanceData, balanceLoading, setSelectedSecurityId, onMarketDataOpen} = props;

  const columns = useMemo(() => tableColumnsTopCreators, [tableColumnsTopCreators]);
  const data = useMemo(() => balanceData, [balanceData]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isShowBalance, setIsShowBalance] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [selectedAccount, setSelectedAccount] = useState(undefined);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    prepareRow
  } =
    tableInstance;

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX="auto">
        <Flex
          direction={{base: 'column', md: 'row'}}
          align={{sm: 'flex-start', md: 'center'}}
          justify="space-between"
          w="100%"
          px="22px"
          pb="20px"
          mb="10px"
          boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)">
          <Text color={textColor} fontSize="xl" fontWeight="600">
            Balances
          </Text>
          <Flex
            width="100%"
            align="center"
            justify="flex-end"
            gap={2}
          >
            <Button
              minW="97px"
              display={{
                base: 'block',
                lg: 'none'
              }}
              variant="action"
              onClick={() => setIsExpanded(prev => !prev)}
            >{isExpanded ? 'Collapse' : 'Expand'}</Button>
            <Button
              variant="no-hover"
              bg="transparent"
              p="0px"
              minW="unset"
              minH="unset"
              h="18px"
              w="max-content"
              disabled={!canPreviousPage}
              onClick={previousPage}
            >
              <Icon cursor="pointer" as={MdKeyboardArrowLeft} color={brandColor} w="24px"
                    h="24px" />
            </Button>
            <Button
              variant="no-hover"
              bg="transparent"
              p="0px"
              minW="unset"
              minH="unset"
              h="18px"
              w="max-content"
              disabled={!canNextPage}
              onClick={nextPage}
            >
              <Icon cursor="pointer" as={MdKeyboardArrowRight} color={brandColor} w="24px"
                    h="24px" />
            </Button>
            <Button
              minW="140px"
              onClick={() => setIsShowBalance(prev => !prev)}
            >{isShowBalance ? 'Hide' : 'Show'} Balance</Button>
          </Flex>
        </Flex>
        {
          isExpanded && <Table {...getTableProps()} variant="simple" color="gray.500">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor="transparent">
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{sm: '10px', lg: '12px'}}
                        color="gray.400">
                        {column.render('Header')}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody {...getTableBodyProps()}>
              {
                balanceLoading ? <>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                    <Tr key={item}>
                      <Td colSpan={6} p={2}>
                        <Skeleton h="20px" />
                      </Td>
                    </Tr>
                  ))}
                </> : page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        let data = '';
                        if (cell.column.Header === 'Name') {
                          data = (
                            <Flex align="center">
                              <Avatar
                                src={cell.row.original.icon}
                                w="30px"
                                h="30px"
                                me="8px"
                              />
                              <Text
                                color={textColor}
                                fontSize="md"
                                title={cell.row.original.title}
                                fontWeight="600">
                                {cell.value}
                              </Text>
                            </Flex>
                          );
                        } else if (cell.column.Header === 'Portfolio') {
                          data = (
                            <Button
                              colorScheme='brand'
                              fontSize="sm"
                              size='sm'
                              onClick={() => {
                                setSelectedAccount(cell.row.original)
                                onOpen();
                              }}>
                              smartportfolio
                            </Button>
                          );
                        } else if (cell.column.Header === 'Balance') {
                          data = (
                            <Text
                              color={textColorSecondary}
                              fontSize="md"
                              fontWeight="500">
                              {
                                isShowBalance ? `$${numberFormat(cell.value)}` : <Skeleton h="20px" />
                              }
                            </Text>
                          );
                        }
                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{sm: '14px'}}
                            py="8px"
                            minW={{sm: '150px', md: '200px', lg: 'auto'}}
                            borderColor="transparent">
                            {data}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        }
        <SmartPortfolioModal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setSelectedAccount(undefined);
          }}
          account={selectedAccount}
          setSelectedSecurityId={setSelectedSecurityId}
          onMarketDataOpen={onMarketDataOpen}
        />
      </Flex>
    </>
  );
}

export default AccountBalance;
