import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react';
import { isValidEmail, isValidUSPhone } from "../../../../libraries/utils";
import { LinkIcon } from "@chakra-ui/icons";

interface UserAddModalProps {
  isOpen: boolean,
  onClose: () => void,
  user: any,
  onSave: (e: {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    companyName: string,
    calendarLink: string,
  }) => Promise<void>
}

const UpdateAdvisorModal: React.FC<UserAddModalProps> = ({ isOpen, onClose, onSave, user }) => {
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [calendarLink, setCalendarLink] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const save = async () => {
    let message = null;

    if (!firstName) {
      message = 'First name is required.';
    } else if (!lastName) {
      message = 'Last name is required.';
    } else if (!email) {
      message = 'Email is required.';
    } else if (!isValidEmail(email)) {
      message = 'Invalid email address.';
    } else if (phoneNumber && !isValidUSPhone(phoneNumber)) {
      message = 'Please input valid phone number.';
    }

    if (message) {
      setErrorMessage(message);
      toast({
        title: message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } else {
      setIsSaving(true);
      const phoneNumberDigits = phoneNumber.replace(/\D/g, '');
      await onSave({
        companyName,
        email,
        firstName,
        lastName,
        phoneNumber: phoneNumberDigits ? `+1${phoneNumberDigits}` : '',
        calendarLink,
      });
      setIsSaving(false);
      handleClose();
    }
  }

  useEffect(() => {
    setDefaultUserInfo();
  }, [user]);

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let onlyNums = value.replace(/\D/g, '');
    let formattedNumber;
    if (onlyNums.length <= 3) {
      formattedNumber = onlyNums;
    } else if (onlyNums.length <= 6) {
      formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
    } else {
      formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    }

    setPhoneNumber(formattedNumber);
    setErrorMessage(null);
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setErrorMessage(null);
  }

  const handleCompanyName = (value: string) => {
    setCompanyName(value);
    setErrorMessage(null);
  }

  const handleClose = () => {
    onClose();
    setDefaultUserInfo();
    setErrorMessage(null);
  }

  const setDefaultUserInfo = () => {
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
    setEmail(user?.email);
    setPhoneNumber(user?.phoneNumber ? user.phoneNumber.replace(/\+1/g, '') : '');
    setCompanyName(user?.fullName);
  }

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose()} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Information</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid
            templateColumns={useBreakpointValue({ base: '1fr', md: 'repeat(2, 1fr)' })}
            gap={useBreakpointValue({ base: 4, md: 2 })}
          >
            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input placeholder='John' value={firstName}
                     onChange={(e) => {
                       setFirstName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input placeholder='Doe' value={lastName}
                     onChange={(e) => {
                       setLastName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
          </Grid>
          <Grid
            mt="12px"
            templateColumns={useBreakpointValue({ base: '1fr', md: 'repeat(2, 1fr)' })}
            gap={useBreakpointValue({ base: 4, md: 2 })}
          >

            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input placeholder='you@example.com' type="email" colorScheme="brandScheme" value={email} readOnly
                     onChange={(e) => handleEmailChange(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Input colorScheme="brandScheme" value={phoneNumber}
                     type="tel"
                     placeholder="(201)-555-0123 (Optional)"
                     onChange={(e) => handlePhoneNumberChange(e)} />
            </FormControl>
            <FormControl>
              <FormLabel>Company Name</FormLabel>
              <Input placeholder='Smartdata' colorScheme="brandScheme" value={companyName}
                     onChange={(e) => handleCompanyName(e.target.value)} marginBottom='8px' />
            </FormControl>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>Calendar Link</FormLabel>
                <InputGroup>
                  <InputLeftAddon
                    pointerEvents='none'
                    children={<LinkIcon color='brand.300' />}
                  />
                  <Input placeholder='Paste your booking link here.' colorScheme="brandScheme" value={calendarLink}
                         onChange={(e) => setCalendarLink(e.target.value)} marginBottom='8px' />
                </InputGroup>
                <Text display='inline-block' fontSize='sm' fontWeight='400' color='gray.500'>
                  This link is included in the success email when a prospect connects an account.
                </Text>
              </FormControl>
            </GridItem>

            <FormControl>
              {errorMessage ? (
                <FormHelperText color='red'>
                  {errorMessage}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brandScheme" mr={3} onClick={() => save()} isLoading={isSaving} isDisabled={isSaving}
                  loadingText='Saving'>Save</Button>
          <Button colorScheme="brandScheme" variant="ghost" onClick={() => handleClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UpdateAdvisorModal;
