import {
  Button,
  Flex,
  Icon,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable, } from 'react-table';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { countDecimals } from "../../../../libraries/utils";

const tableColumnsTopCreators = [
  {
    Header: 'Symbol',
    accessor: 'tickerSymbol',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Exp Ratio',
    accessor: 'expenseRatio',
  },
  {
    Header: '12B-1 Fee',
    accessor: 'b12',
  },
  {
    Header: 'Front Load',
    accessor: 'frontLoad',
  },
];

function ExpenseRatio(props) {
  const {securityData, isSecurityLoading, setSelectedSecurityId, onOpen} = props;

  const columns = useMemo(() => tableColumnsTopCreators, [tableColumnsTopCreators]);
  const [showAll, setShowAll] = useState(false);
  const data = useMemo(() => {
    if (showAll) return securityData;
    return securityData.filter(security => security.expenseRatio !== null);
  }, [securityData, showAll]);
  const [isExpanded, setIsExpanded] = useState(true);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    prepareRow
  } =
    tableInstance;

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX="auto">
        <VStack
          px="22px"
          pb="20px"
          mb="10px"
          align="start"
          boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
        >
          <Flex
            w="100%"
            align={{sm: 'flex-start', lg: 'center'}}
            justify="space-between">
            <Text color={textColor} fontSize="xl" fontWeight="600">
              smartfees™
              <Text display='inline' fontSize='md'> ({data?.length})</Text>
            </Text>
            <Flex
              align="center"
              gap={2}
            >
              <Button display={{
                base: 'block',
                lg: 'none'
              }}
                      variant="action"
                      onClick={() => setIsExpanded(prev => !prev)}
              >{isExpanded ? 'Collapse' : 'Expand'}</Button>
              <Button
                variant="no-hover"
                bg="transparent"
                p="0px"
                minW="unset"
                minH="unset"
                h="18px"
                w="max-content"
                disabled={!canPreviousPage}
                onClick={previousPage}
              >
                <Icon cursor="pointer" as={MdKeyboardArrowLeft} color={brandColor} w="24px"
                      h="24px" />
              </Button>
              <Button
                variant="no-hover"
                bg="transparent"
                p="0px"
                minW="unset"
                minH="unset"
                h="18px"
                w="max-content"
                disabled={!canNextPage}
                onClick={nextPage}
              >
                <Icon cursor="pointer" as={MdKeyboardArrowRight} color={brandColor} w="24px"
                      h="24px" />
              </Button>
            </Flex>
          </Flex>
          <Button
            bgColor={bgButton}
            _hover={{bg: 'whiteAlpha.200'}}
            _active={{bg: 'whiteAlpha.100'}}
            size='sm'
            onClick={() => setShowAll(prev => !prev)}
            variant="action">
            {showAll ? 'Show Fees' : 'Show All'}
          </Button>
        </VStack>
        {
          isExpanded && <Table {...getTableProps()} variant="simple" color="gray.500">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor="transparent">
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{sm: '10px', lg: '12px'}}
                        color="gray.400">
                        {column.render('Header')}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody {...getTableBodyProps()}>
              {isSecurityLoading ? <>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                  <Tr key={item}>
                    <Td colSpan={6} p={2}>
                      <Skeleton h="20px" />
                    </Td>
                  </Tr>
                ))}
              </> : page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = '';
                      if (cell.column.Header === 'Symbol') {
                        data = (
                          <Button size='sm' variant='link'
                                  onClick={() => {
                                    setSelectedSecurityId(cell.row.original.securityId);
                                    onOpen();
                                  }}>
                            <Tooltip label={cell.row.original.name} hasArrow placement="top">
                              <Text
                                color={brandColor}
                                fontSize="lg"
                                fontWeight="500">
                                {cell.value}
                              </Text>
                            </Tooltip>
                          </Button>
                        );
                      } else if (cell.column.Header === 'Name') {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="lg"
                            fontWeight="500">
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === 'Exp Ratio' || cell.column.Header === '12B-1 Fee' || cell.column.Header === 'Front Load') {
                        const maxDigits = countDecimals(cell.value);
                        data = (
                          <Text
                            color={cell.value === null ? textColorSecondary : brandColor}
                            fontSize="lg"
                            fontWeight="500">
                            {cell.value === null ? 'N/A' : `${(cell.value * 100).toLocaleString(undefined, {maximumFractionDigits: maxDigits})}%`}
                          </Text>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{sm: '14px'}}
                          py="8px"
                          minW={{sm: '150px', md: '200px', lg: 'auto'}}
                          borderColor="transparent">
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        }
      </Flex>
    </>
  );
}

export default ExpenseRatio;
