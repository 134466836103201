import React from 'react';
import { Icon } from '@chakra-ui/react';
import {
  MdAddHomeWork,
  MdBarChart,
  MdDomain,
  MdEditDocument,
  MdHome,
  MdList,
  MdLogout,
  MdMonetizationOn,
  MdOutlineShoppingCart,
  MdPeople,
  MdPerson,
  MdSettings,
  MdSupervisorAccount,
  MdVerifiedUser
} from 'react-icons/md';

// Admin Imports
import MainDashboard from './views/admin/default';
import NFTMarketplace from './views/admin/marketplace';
import Profile from './views/admin/profile';
import DataTables from './views/admin/dataTables';

// Auth Imports
import SignIn from './views/auth/signIn/index';
import SignUp from './views/auth/signUp/index';
import ResetPassword from './views/auth/resetPassword/index';
import MFASetup from './views/auth/mfa/index';
import UserManagement from './views/auth/users/index';
import ClientManagement from './views/auth/clients';
import Customers from './views/auth/customers';
import ResetEmail from './views/auth/resetPassword/reset-email';
import ConfirmReset from './views/auth/resetPassword/confirm-reset';
import InsuranceDashboard from "./views/admin/insurance";
import SmartFillDashboard from "./views/admin/smart-fill";
import ClientComponent from "./views/auth/clients/ClientDetail";
import Settings from "./views/admin/settings";
import AdvisorList from "./views/auth/advisors";
import SmartLeads from "./views/auth/smartleads";
import ConfirmLogin from "./views/auth/signIn/confirm-login";
import SmarttaxOrders from "./views/admin/smarttax";
import OnboardingForm from "./views/admin/onboarding-form";

export interface RoutesProps {
  name: string,
  layout: string,
  path: string,
  icon: JSX.Element,
  component: React.FC<{}>
  secondary?: boolean
  hide?: boolean,
  collapse?: boolean,
  category?: boolean,
  messageNavbar?: string,
  items?: RoutesProps[] | RoutesProps | undefined
}

const routes: RoutesProps[] = [
  {
    name: 'Prospect Detail',
    layout: '/admin',
    path: '/clients/:id',
    icon: (
      <Icon as={MdPeople} width="20px" height="20px" color="inherit" />
    ),
    component: ClientComponent,
    hide: true
  },
  {
    name: 'Users',
    layout: '/admin',
    path: '/users',
    icon: (
      <Icon as={MdDomain} width="20px" height="20px" color="inherit" />
    ),
    component: AdvisorList,
    hide: false
  },
  {
    name: 'Prospects',
    layout: '/admin',
    path: '/clients',
    icon: (
      <Icon as={MdPeople} width="20px" height="20px" color="inherit" />
    ),
    component: ClientManagement,
    hide: false
  },
  {
    name: 'Link/QR Code',
    layout: '/admin',
    path: '/smartleads',
    icon: (
      <Icon as={MdAddHomeWork} width="20px" height="20px" color="inherit" />
    ),
    component: SmartLeads,
    hide: false
  },
  {
    name: 'Wealth Management',
    layout: '/admin',
    path: '/wealth',
    icon: <Icon as={MdMonetizationOn} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: 'Smarttax Orders',
    layout: '/admin',
    path: '/smarttax',
    icon: (
      <Icon as={MdList} width="20px" height="20px" color="inherit" />
    ),
    component: SmarttaxOrders,
    hide: false
  },
  {
    name: 'Insurance Management',
    layout: '/admin',
    path: '/insurance',
    icon: <Icon as={MdVerifiedUser} width="20px" height="20px" color="inherit" />,
    component: InsuranceDashboard,
  },
  {
    name: 'Smart Fill',
    layout: '/admin',
    path: '/smart-fill',
    icon: <Icon as={MdEditDocument} width="20px" height="20px" color="inherit" />,
    component: SmartFillDashboard,
    hide: true,
  },
  {
    name: 'Operations',
    layout: '/admin',
    path: '/operations',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: DataTables,
    hide: true
  },
  {
    name: 'Sales / Growth',
    layout: '/admin',
    path: '/sales',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: DataTables,
    hide: true
  },
  {
    name: 'Customer Success',
    layout: '/admin',
    path: '/customer-success',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: DataTables,
    hide: true
  },
  {
    name: 'Client / Bookkeeper',
    layout: '/admin',
    path: '/customers',
    icon: (
      <Icon as={MdSupervisorAccount} width="20px" height="20px" color="inherit" />
    ),
    component: Customers,
    hide: true,
  },
  {
    name: 'User Management',
    layout: '/admin',
    path: '/users',
    icon: (
      <Icon as={MdVerifiedUser} width="20px" height="20px" color="inherit" />
    ),
    component: UserManagement,
    hide: true,
  },
  {
    name: 'NFT Marketplace',
    layout: '/admin',
    path: '/nft-marketplace',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: NFTMarketplace,
    secondary: true,
    hide: true
  },
  {
    name: 'Data Tables',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/data-tables',
    component: DataTables,
    hide: true
  },
  {
    name: 'Settings',
    layout: '/admin',
    path: '/settings',
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    component: Settings,
    hide: true
  },
  {
    name: 'Profile',
    layout: '/admin',
    path: '/profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
    hide: false
  },
  {
    name: 'Onboarding Form',
    layout: '/admin',
    path: '/onboarding-form',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: OnboardingForm,
    hide: false
  },
  // {
  //   name: 'Security Management',
  //   layout: '/admin',
  //   path: '/manage',
  //   icon: <Icon as={MdCandlestickChart} width="20px" height="20px" color="inherit" />,
  //   component: Manage,
  // },
  {
    name: 'Signin',
    layout: '/auth',
    path: '/login',
    icon: (
      <Icon as={MdHome} width="20px" height="20px" color="inherit" />
    ),
    component: SignIn,
    hide: true
  },
  {
    name: 'Sign up',
    layout: '/auth',
    path: '/signup',
    icon: (
      <Icon as={MdHome} width="20px" height="20px" color="inherit" />
    ),
    component: SignUp,
    hide: true
  },
  {
    name: 'Reset password',
    layout: '/auth',
    path: '/reset',
    icon: (
      <Icon as={MdHome} width="20px" height="20px" color="inherit" />
    ),
    component: ResetPassword,
    hide: true
  },
  {
    name: 'Forgot password',
    layout: '/auth',
    path: '/forgot-password',
    icon: (
      <Icon as={MdHome} width="20px" height="20px" color="inherit" />
    ),
    component: ResetEmail,
    hide: true
  },
  {
    name: 'Forgot password',
    layout: '/auth',
    path: '/confirm-reset-password',
    icon: (
      <Icon as={MdHome} width="20px" height="20px" color="inherit" />
    ),
    component: ConfirmReset,
    hide: true
  },
  {
    name: 'MFA Setup',
    layout: '/auth',
    path: '/mfa/setup',
    icon: (
      <Icon as={MdHome} width="20px" height="20px" color="inherit" />
    ),
    component: MFASetup,
    hide: true
  },
  {
    name: 'Confirm Login',
    layout: '/auth',
    path: '/confirm-login',
    icon: (
      <Icon as={MdHome} width="20px" height="20px" color="inherit" />
    ),
    component: ConfirmLogin,
    hide: true
  },
];

export const Logout: RoutesProps[] = [
  {
    name: 'Log Out',
    layout: '/auth',
    path: '/sign-out',
    icon: (
      <Icon as={MdLogout} width="20px" height="20px" color="inherit" />
    ),
    component: SignIn,
  }
];
export default routes;
