import moment from 'moment';
import {getDefaultDate} from './utils';

export const YEAR = 'YEAR';
export const CURRENT_MONTH = 'CURRENT_MONTH';
export const YEAR_1 = 'YEAR_1';
export const PREVIOUS_MONTH = 'PREVIOUS_MONTH';
export const Q1 = 'Q1';
export const Q2 = 'Q2';
export const Q3 = 'Q3';
export const Q4 = 'Q4';
export const CUSTOM = 'CUSTOM';

export const FILTERS = {
	FEE: {
		MIN: 0,
		MAX: 0,
	},
	SELECTED_OPTION: YEAR_1,
	CUSTOM_DATE: getDefaultDate(),
	SEARCH: '',
	ACCOUNT_NAME: '',
	INVESTMENT_TYPE: [],
	SHOW_ALL: true,
};

export const TRANSACTIONS_DATE_FORMAT = 'YYYY-MM-DD';

export const MAX_ITEMS_PER_PAGE = 20;

export const filterIncomes = transactions => transactions
	.filter(transaction => (transaction.amount < 0));

export const filterExpenses = transactions => transactions
	.filter(transaction => (transaction.amount > 0));

const filterByFee = (feeRange, transactions) => {
	if (!feeRange.MIN && !feeRange.MAX) return transactions;
	return transactions
		.filter((transaction) => {
			const absoluteFee = Math.abs(transaction.amount);
			if (feeRange.MAX === 0 || feeRange.MAX === '') {
				return absoluteFee >= feeRange.MIN;
			}
			return absoluteFee >= feeRange.MIN && absoluteFee <= feeRange.MAX;
		});
};

export const filterByMonth = (month, transactions, year = moment().year()) => {
	const firstDayOfMonth = moment([year, month]).startOf('month');
	const lastDayOfMonth = moment([year, month]).endOf('month');

	return transactions
		.filter((transaction) => {
			const date = moment(transaction.date, TRANSACTIONS_DATE_FORMAT, true);
			return date
					.isBetween(firstDayOfMonth, lastDayOfMonth)
				|| date.isSame(firstDayOfMonth)
				|| date.isSame(lastDayOfMonth);
		});
};

export const filterBySearch = (search, transactions) => {
	if (search === '') return transactions;
	return transactions
		.filter(transaction => transaction.name.toLowerCase()
			.includes(search.toLowerCase()) || (transaction.memo && transaction.memo.toLowerCase()
			.includes(search.toLocaleString()))
		);
};


export const filterBy = (filters, transactions) => {
	let filtered = [...transactions];
	if (filters.FEE) {
		filtered = filterByFee(filters.FEE, filtered);
	}
	if (filters.IS_SELECTING_CUSTOM_DATE) {
		const [startDate, endDate] = filters.CUSTOM_DATE;
		if (startDate && endDate && startDate.isValid && endDate.isValid()) {
			filtered = filterByCustomDateRange(startDate, endDate, filtered);
		}
	} else if (filters.MONTH === -2) { // year to date
		const yearStart = moment().startOf('year').format('YYYY-MM-DD');
		const today = moment().format('YYYY-MM-DD');
		filtered = filterByCustomDateRange(yearStart, today, filtered);
	} else {
		const isValidMonth = filters.MONTH >= 0 && filters.MONTH <= 12;
		if (isValidMonth) {
			filtered = filterByMonth(filters.MONTH, filtered);
		}
	}
	if (filters.SEARCH) {
		filtered = filterBySearch(filters.SEARCH, filtered);
	}
	return filtered;
};

export const filterByAccountName = (accountNames, transactions) => transactions.filter(
	({institution, accountNumberDisplay}) => accountNames.includes(`${institution} ${accountNumberDisplay}`) ||
		(accountNames.includes(institution) && institution === 'Manual Transactions'),
);

export const filterByTag = (selected, transactions) => transactions.filter(
	({tags}) => selected.some(element => tags && tags.split('#').indexOf(element) > -1),
);

export const filterByCustomDateRange = (startDate, endDate, transactions) => (
	[...transactions]
		.filter((transaction) => {
			const date = moment(transaction.date, TRANSACTIONS_DATE_FORMAT, true);
			return date.isBetween(startDate, endDate)
				|| date.isSame(startDate)
				|| date.isSame(endDate);
		})
);
