// Chakra imports
import {
  Button,
  Flex,
  Grid,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react';
// Assets
// Custom components
import Card from 'components/card/Card';
import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import {
  exchangePublicToken,
  getAllLinkedAccounts,
  getHistoricalSyncStatus,
  refreshInstitution,
  removeInstitution,
  startHistoricalSyncProcess
} from '../../../../api/institution-service';
import Account from './Account';
import { generateConnectFixUrl, generateConnectUrl, generateUploadtUrl } from '../../../../api/finicity-service';
import Joyride from "react-joyride";
import { useHistory } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(window.location.search);
}

export default function LinkedAccounts({connected, onConnected}) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue("gray.500", "white");
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset'
  );

  const toast = useToast();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [linkedInstitutions, setLinkedInstitutions] = useState([]);
  const [timerId, setTimerId] = useState(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isUploadingURL, setIsUploadingURL] = useState(false);

  const query = useQuery();
  const reason = query.get("reason");
  const code = query.get("code");

  const steps = [
    {
      target: '.add-investment-button',
      placement: 'left',
      hideCloseButton: false,
      disableOverlayClose: true,
      disableBeacon: true,
      content: 'Link your account now to let us help you retire sooner with more money.',
    },
  ];

  useEffect(() => {
    if (reason === 'complete' && code === '200') {
      toast({
        title: 'Wait a moment...',
        description: `Your account has been added successfully. We are now in the process of pulling your data. This might take a few moments.`,
        status: 'success',
        duration: 10000,
        isClosable: false,
      });
      setTimeout(() => {
        onOpen();
      }, 10000);
    }
  }, [reason]);

  const connectFinicity = () => {
    setIsProcessing(true);
    generateConnectUrl().then(({link}) => {
      window.location.href = link;
    }).catch(error => {
      console.error(error.message);
      toast({
        title: 'Add Investment Account',
        description: error.response?.data.message ?? error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    })
      .finally(() => {
        setIsProcessing(false);
      })
  }

  const connectFixFinicity = async (itemId) => {
    setIsSyncing(true);
    await refreshInstitution(itemId);
    generateConnectFixUrl(itemId).then(({link}) => {
      window.location.href = link;
    }).catch(error => {
      console.error(error.message);
      toast({
        title: 'Connect Fix Account',
        description: error.response?.data.message ?? error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    })
      .finally(() => setIsSyncing(false))
  }

  const uploadtStatementURL = () => {
    setIsUploadingURL(true);
    generateUploadtUrl().then(({link}) => {
      window.open(link, '_blank');
    }).catch(error => {
      console.error(error.message);
      toast({
        title: 'Upload Statement',
        description: error.response?.data.message ?? error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    })
      .finally(() => {
        setIsUploadingURL(false);
      })
  }

  useEffect(() => {
    fetchLinkedAccounts();
  }, []);

  useEffect(() => {
    onConnected(linkedInstitutions?.length > 0);
  }, [linkedInstitutions]);

  const fetchLinkedAccounts = () => {
    setIsLoading(true);
    getAllLinkedAccounts()
      .then((result) => {
        setLinkedInstitutions(result);
      })
      .catch(err => console.error(err.message))
      .finally(() => setIsLoading(false))
  }

  const {open, ready} = usePlaidLink({
    onSuccess: async (public_token, metadata) => {
      // send public_token to server
      const publicToken = public_token;
      const {accounts, institution: {institution_id: institutionId, name: institutionName}} = metadata;
      try {
        setIsProcessing(true);

        const linkedAccount = linkedInstitutions.find(item => item.institutionId === institutionId);
        let existAccounts = [];
        if (linkedAccount) {
          linkedAccount.items.forEach(item => {
            item.accounts.forEach(account => {
              const existAccount = accounts.find(el => `${el.name}${el.accountNumberDisplay}` === `${account.accountName}${account.accountNumberDisplay}`)
              if (existAccount) {
                existAccounts.push(existAccount);
              }
            })
          })
        }

        if (existAccounts.length > 0) {
          const toastContent = `Existing Accounts (${existAccounts.map(item => `${item.name} ${item.accountNumberDisplay}`).join(', ')})`;
          toast({
            title: 'Add Client',
            description: toastContent,
            status: 'warning',
            duration: 9000,
            isClosable: true,
          })
        }

        const newAccounts = accounts.filter(account => !existAccounts.some(item => account.id === item.id));
        const selectedAccounts = newAccounts.map(acc => acc.id);
        console.log('selectedAccounts', selectedAccounts);
        console.log('existsAccounts', existAccounts);

        if (selectedAccounts.length === 0) {
          setIsProcessing(false);
          return;
        }
        // const getLogoResponse = await getInstitutionById(institutionId);
        // const {logo, primaryColor} = getLogoResponse.data.institution;

        const {data: itemId} = await exchangePublicToken(publicToken, selectedAccounts);
        fetchLinkedAccounts();
        // const newInstitution = {
        // 	institutionId,
        // 	institutionName,
        // 	logo,
        // 	primaryColor,
        // 	items: [
        // 		{
        // 			itemId,
        // 			hasError: false,
        // 			historicalSyncStatus: 'waiting_historical_hook',
        // 			accounts: newAccounts.map(({id, name, accountNumberDisplay}) => ({
        // 				accountId: id,
        // 				accountName: name,
        // 				accountNumberDisplay,
        // 				included: true,
        // 			})),
        // 		},
        // 	],
        // };
        // const tempLinkedAccounts = [...linkedAccounts];
        // const idx = tempLinkedAccounts.findIndex(ac => ac.institutionId === institutionId);
        //
        // console.log('idx', idx, tempLinkedAccounts);
        //
        // if (idx === -1) {
        // 	setLinkedAccounts(prev => [...prev, newInstitution].sort((a, b) => {
        // 		const prev = a.institutionName.toLowerCase();
        // 		const next = b.institutionName.toLowerCase();
        // 		if (prev < next) return -1;
        // 		if (prev > next) return 1;
        // 		return 0;
        // 	}));
        // } else {
        // 	const linkedAccountsCopy = [...linkedAccounts];
        // 	linkedAccountsCopy[idx].items = [
        // 		...linkedAccountsCopy[idx].items,
        // 		...newInstitution.items,
        // 	];
        // 	setLinkedAccounts(linkedAccountsCopy);
        // }
        toast({
          title: 'Add Client',
          description: `You've successfully added a bank account. Please sync your account after a few minutes, as we're about to start fetching your transactions from the bank.`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        setIsProcessing(false);
      } catch (error) {
        setIsProcessing(false);
        toast({
          title: 'Add Client',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    },
  });

  const getHistoricalSyncCallProcess = async (institutionId, itemId) => {
    if (timerId) return;

    const getHistoricalSyncStatusWithDelay = async () => {
      await new Promise((resolve) => setTimeout(resolve, 3000));

      try {
        const {historicalSyncStatus} = await getHistoricalSyncStatus(itemId);
        setTimerId(null);

        if (historicalSyncStatus === 'running') {
          getHistoricalSyncCallProcess(institutionId, itemId);
        }
        const tempLinkedAccounts = [...linkedInstitutions];
        const idx = tempLinkedAccounts.findIndex(ac => ac.institutionId === institutionId);
        const idItem = tempLinkedAccounts[idx].items.findIndex(ac => ac.itemId === itemId);

        tempLinkedAccounts[idx].items[idItem] = {
          ...tempLinkedAccounts[idx].items[idItem],
          historicalSyncStatus,
        };
        setLinkedInstitutions(tempLinkedAccounts);
      } catch (err) {
        console.error(err.message);
      }
    };

    const tid = setTimeout(async () => {
      await getHistoricalSyncStatusWithDelay();
    }, 0);

    setTimerId(tid);
  }

  const startHistoricalSync = (institutionId, itemId) => {
    startHistoricalSyncProcess(itemId)
      .then(({plaidItemsStarted}) => {
        getHistoricalSyncCallProcess(institutionId, itemId);
        toast({
          title: 'Sync account',
          description: `We have received your request. The sync will be started shortly.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: 'Sync account',
          description: 'An error occurred while try to start the sync progress. Please try again later.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })
  }

  const onRemoveItem = (itemId) => {
    setIsDeleting(true);
    removeInstitution(itemId)
      .then(() => {
        const tempLinkedAccounts = [...linkedInstitutions];
        const updatedAccounts = tempLinkedAccounts.map(account => ({
          ...account,
          items: account.items.filter(item => item.itemId !== itemId),
        }))
          .filter(account => account.items.length > 0);

        setLinkedInstitutions(updatedAccounts);
        toast({
          title: 'Remove account',
          description: `Your account has been removed.`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: 'Remove account',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }).finally(() => setIsDeleting(false))
  }

  return (
    <Card mb={{base: '0px', '2xl': '20px'}}>
      {
        (!isLoading && linkedInstitutions.length === 0) && <Joyride
          steps={steps}
          continuous
          run
          showSkipButton={true}
          showProgress={false}
          spotlightPadding={10}
          spotlightClicks={true}
          locale={{last: 'OK'}}
          styles={{
            options: {
              primaryColor: '#70ad45',
              arrowColor: '#fff',
              backgroundColor: '#fff',
              overlayColor: 'rgba(0, 0, 0, 0.7)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              zIndex: 100,
            },
            spotlight: {
              backgroundColor: 'rgba(255, 255, 255, 0.4)',
              borderRadius: 20,
            },
            tooltip: {
              borderRadius: 20,
            }
          }}
        />
      }
      <Flex
        mb="20px"
        direction={{base: 'column', md: 'row'}}
        align={{md: 'center'}}
        justify="space-between"
      >
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px">
          Linked Accounts
        </Text>
        <Button
          minW="170px"
          variant="brand"
          fontWeight="500"
          className="add-investment-button"
          onClick={connectFinicity}
          isLoading={isProcessing}
          disabled={isLoading || isProcessing}
        >
          Connect Your Account
        </Button>
      </Flex>
      <VStack mb={4}>
        <Text textAlign='center'>
          You have a trouble to connect your account? Click "Upload Statement" to upload your statements.
        </Text>
        <Button
          minW="170px"
          variant="brand"
          fontWeight="500"
          onClick={uploadtStatementURL}
          isLoading={isUploadingURL}
          disabled={isLoading || isUploadingURL}
        >
          Upload Statement
        </Button>
      </VStack>
      {
        !connected && !isLoading && <Flex
          justify='center' align='center' direction='column'>
          <VStack>
            <Text
              color={textColorPrimary}
              fontWeight="bold"
              fontSize="lg"
              textAlign='center'
              mt="36px">
              Trusted and Secure
            </Text>
            <Text
              color={textColorSecondary}
              fontSize="md"
              fontWeight='500'
              lineHeight='140%'
              textAlign='center'
              mb="4px">
              Smartdata is where trust meets technology. We never see or store your username and password. We have the
              following features to always safeguard your data using bank level encryption.
            </Text>
          </VStack>
          <Grid
            templateColumns={{
              base: 'none', md: 'repeat(2, 1fr)',
            }}
            templateRows={{
              base: 'none', lg: '1fr',
            }}>
            <Flex justify='start' align='center' direction='column' margin='12px'>
              <Image src={require('../../../../assets/img/financials/safe-vault.png')}
                     w='80px'
                     borderRadius='full'
                     h="auto" />
              <Text color={textColorPrimary}
                    fontSize="lg"
                    fontWeight='bold'
                    textAlign='center'
                    mb="4px">
                Cyber Protection Coverage
              </Text>
            </Flex>
            <Flex justify='start' align='center' direction='column' margin='12px'>
              <Image src={require('../../../../assets/img/financials/encryption.png')}
                     w='80px'
                     h="auto" />
              <Text color={textColorPrimary}
                    fontSize="lg"
                    fontWeight='bold'
                    textAlign='center'
                    mb="4px">
                End-to-End Encryption
              </Text>
            </Flex>
          </Grid>
        </Flex>
      }
      {
        (isLoading || isProcessing) ? [0, 1, 2, 3].map((index) => (
          <Skeleton key={index} mb="20px" h="80px" />
        )) : linkedInstitutions.map((institution) => (
          <Account
            key={institution.institutionId}
            boxShadow={cardShadow}
            mb="20px"
            institutionId={institution.institutionId}
            institutionName={institution.institutionName}
            branding={institution.branding}
            items={institution.items}
            onStartSync={startHistoricalSync}
            onRemoveItem={onRemoveItem}
            connectFixFinicity={connectFixFinicity}
            isDeleting={isDeleting}
            isSyncying={isSyncing}
          />
        ))
      }
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Investment Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              color={textColorPrimary}
              fontSize="lg"
              fontWeight="500">
              Investment account successfully added and securely shared with the advisor. We take your privacy seriously
              and will never reuse or re-access your data.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="brandScheme" variant="ghost" onClick={() => {
              history.push(window.location.pathname);
              onClose();
              fetchLinkedAccounts();
            }}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
