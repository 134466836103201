// Chakra imports
import { Box, Portal, useDisclosure } from '@chakra-ui/react';
// Layout components
import Navbar from '../../components/navbar/NavbarAdmin';
import Sidebar from '../../components/sidebar/Sidebar';
import { SidebarContext } from '../../contexts/SidebarContext';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes, { RoutesProps } from '../../routes';
import { useAuth } from '../../auth-context/auth.context';
import { AccountType } from '../../config/constant';
import { validateRole } from "../../libraries/utils";

// Custom Chakra theme
const Dashboard: React.FC<{ rest: any }> = (props: { rest: any }) => {
  const {...rest} = props;
  const {user, selectedClient} = useAuth();
  const customRoutes: RoutesProps[] = [];

  if (user && validateRole(user?.token) === AccountType.CLIENT) {
    customRoutes.push(
      ...routes.filter((item) => {
        return ![
          'Prospects',
          'Link/QR Code',
          'Wealth Management',
          'Insurance Management',
          'Users',
          'Settings',
          'Smarttax Orders'
        ].includes(item.name);
      })
    );
  }

  if (user && validateRole(user?.token) === AccountType.ADVISOR) {
    customRoutes.push(
      ...routes.filter((item) => {
        if (
          item.name === 'Profile'
        ) {
          return false;
        }
        if (
          item.name === 'Users' && user?.firmRole !== 'admin'
        ) {
          return false;
        }
        if (
          item.name === 'Wealth Management' &&
          selectedClient?.management !== "financial"
        ) {
          return false;
        }
        if (
          item.name === 'Insurance Management' &&
          selectedClient?.management !== "insurance"
        ) {
          return false;
        }
        if (
          item.name === 'Smarttax Orders' &&
          selectedClient?.management !== "financial"
        ) {
          return false;
        }
        if (
          item.name === 'Onboarding Form'
        ) {
          return false;
        }
        return true;
      })
    );
  }
  if (!user) {
    customRoutes.push(...routes);
  }

  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };
  const getActiveRoute: any = (routes: RoutesProps[]) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar: any = (routes: RoutesProps[]) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText: any = (routes: RoutesProps[]) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes: any = (routes: RoutesProps[]) => {
    return routes.map((prop: RoutesProps, key: number) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            path={ prop.layout + prop.path }
            component={ prop.component }
            key={ key }
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = 'ltr';
  const {onOpen} = useDisclosure();

  return (
    <Box>
      <SidebarContext.Provider
        value={ {
          toggleSidebar,
          setToggleSidebar,
        } }
      >
        <Sidebar routes={ customRoutes } display="none" { ...rest } />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={ {base: '100%', xl: 'calc( 100% - 240px )'} }
          maxWidth={ {base: '100%', xl: 'calc( 100% - 240px )'} }
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={ onOpen }
                logoText="Smartdata"
                brandText={ getActiveRoute(customRoutes) }
                secondary={ getActiveNavbar(customRoutes) }
                message={ getActiveNavbarText(customRoutes) }
                fixed={ fixed }
                { ...rest }
              />
            </Box>
          </Portal>

          { getRoute() && (
            <Box
              mx="auto"
              p={ {base: '20px', md: '30px'} }
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Switch>
                { getRoutes(customRoutes) }
                <Redirect from="/" to="/admin/wealth"/>
              </Switch>
            </Box>
          ) }
          {/* <Box>
            <Footer />
          </Box> */ }
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
};

export default Dashboard;
