import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { RotatingLines } from "react-loader-spinner";

interface OfficeInviteModalProps {
  isOpen: boolean,
  onClose: () => void,
  selectedUser: any,
  url: string,
}

const OfficeInviteModal: React.FC<OfficeInviteModalProps> = ({ isOpen, onClose, url, selectedUser }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const handleClose = () => {
    setIsLoading(true);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='full' isCentered>
      <ModalOverlay />
      <ModalContent
        display="flex"
        flexDirection="column"
        height="100vh"  // Set the modal content to full viewport height
      >
        <ModalHeader>{selectedUser?.firstName} {selectedUser?.lastName} - Upload Statement</ModalHeader>
        <ModalCloseButton />
        <ModalBody flex="1" p="0" display="flex" justifyContent="center" alignItems="center" position="relative"
                   w='full'>
          {
            isLoading &&
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10  // Ensures the spinner is above the iframe while loading
            }}>
              <RotatingLines
                visible={true}
                width="80"
                strokeWidth="4"
                animationDuration="0.75"
                strokeColor="#3A9E3E"
                ariaLabel="rotating-lines-loading"
              />
            </div>
          }
          <iframe
            onLoad={handleIframeLoad}
            src={url}
            width="100%"
            height="100%"
            style={{ border: 0 }}
            title="Smartfinance Office Invite Page"
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={handleClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default OfficeInviteModal;