import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import Select from "react-select";
import { getAllAdvisorsByFirmId } from "../../../../api/user-service";
import { useAuth } from "../../../../auth-context/auth.context";

interface DeleteAdvisorModalProps {
  advisor: any,
  isOpen: boolean,
  onClose: () => void,
  onDelete: (e: { toUserId: number }) => Promise<void>
};

const DeleteAdvisorModal: React.FC<DeleteAdvisorModalProps> = ({ isOpen, onClose, onDelete, advisor }) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [advisorList, setAdvisorList] = useState<any>([]);
  const [toAdvisor, setToAdvisor] = useState<any>(null);

  useEffect(() => {
    if (!user) return;
    setIsLoading(true);
    getAllAdvisorsByFirmId(user.firmId)
      .then(({ advisors }) => {
        const list = advisors
          .filter((item: any) => item.userId !== advisor.userId)
          .map((item: any) => ({
          value: item.userId,
          label: `${item?.firstName} ${item?.lastName} (${item?.email})`
        }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label));

        const userInfo = {
          value: user.userId,
          label: `${user?.firstName} ${user?.lastName} (${user?.email})`
        };
        setAdvisorList([userInfo, ...list]);
      })
      .catch((err) => {
        console.error(err.message);
        setAdvisorList([]);
      })
      .finally(() => setIsLoading(false))
  }, [user])

  const validate = async () => {
    setIsLoading(true);
    await onDelete({
      toUserId: toAdvisor.value,
    });
    setIsLoading(false);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete {advisor?.firstName} {advisor?.lastName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color='gray.600' mb={2}>
            Before deleting, please ensure prospects are assigned to another advisor to maintain access to prospect data.
          </Text>
          <FormControl id='toUser'>
            <FormLabel>To</FormLabel>
            <Select
              isLoading={isLoading}
              value={toAdvisor}
              isClearable
              placeholder="Transfer to..."
              onChange={(value: string) => setToAdvisor(value)}
              isSearchable
              options={advisorList}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="brandScheme" mr={3} onClick={() => validate()} isLoading={isLoading}
                  isDisabled={!toAdvisor}
                  loadingText='Saving...'>Delete</Button>
          <Button colorScheme="brandScheme" variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DeleteAdvisorModal;
