import React, { useEffect, useState } from 'react';
import { Box, Grid, Text } from "@chakra-ui/react";
import { oembed } from '@loomhq/loom-embed';

type Video = {
  html: string;
  title: string;
};

const sharedVideos = [
  {
    title: 'Customizing Your Smartdata Account',
    url: 'https://www.loom.com/share/0b00e1c3900c4061a2498ee57bd7eb48',
  },
  {
    title: 'Viewing Prospect\'s Portfolio Data 👥',
    url: 'https://www.loom.com/share/8da9cdbcb678439c96dab1aed955c766'
  },
  {
    title: 'Enhancing Prospect Conversion Process with QR Code👩‍💼',
    url: 'https://www.loom.com/share/4bbc6b94769f400d9421a5ad8a243ed9'
  },
  {
    title: 'Invite List and Smartleads 📃',
    url: 'https://www.loom.com/share/a02f45e8e71e4329a57a8cee1b2131d4'
  },
  {
    title: 'Settings Page Customization Options',
    url: 'https://www.loom.com/share/1aaa98aa7d034f73bb2271bf1ea4adf3'
  },
  {
    title: 'Simplifying Prospect Connections',
    url: 'https://www.loom.com/share/455f873fb87c45a1ad47a618c855c961'
  },
  {
    title: 'Prospect Email Example',
    url: 'https://www.loom.com/share/1c7ffa543c954429b0f84db33f82b406'
  },
  {
    title: 'Smartdata Add Prospect/CRM Connection',
    url: 'https://www.loom.com/share/6a82e36719be4847af4753d8a03686a2'
  },
];

const TrainingVideos: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);

  useEffect(() => {
    async function fetchVideos() {
      const videoPromises = sharedVideos.map(async ({ title, url }) => {
        const { html } = await oembed(url, {});
        return { html, title };
      });

      try {
        const videos = await Promise.all(videoPromises);
        setVideos(videos);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    }

    fetchVideos();
  }, []);

  return (
    <Grid
      templateColumns={{
        sm: "repeat(1, 1fr)",
        md: "repeat(2, 1fr)",
        lg: "repeat(3, 1fr)",
      }}
      gap={{ base: 6, md: 10 }}
    >
      {videos.map((video, index) => (
        <Box key={`video-${index}`} borderWidth={1} borderRadius={16}>
          <Box
            dangerouslySetInnerHTML={{ __html: video.html }}
            borderRadius='8px 8px 0 0'
            overflow='hidden'
          />
          <Text p={2} fontWeight='semibold' textAlign='center'>
            {video.title}
          </Text>
        </Box>
      ))}
    </Grid>
  );
};

export default TrainingVideos;