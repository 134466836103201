import { NavLink } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react';
// Custom components
import React, { useEffect, useState } from 'react';
import DefaultAuth from 'layouts/auth/Default';
// Assets
import illustration from 'assets/img/auth/auth.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { getPlanName, isValidUSPhone } from "../../../libraries/utils";
import { createAdvisor } from "../../../api/user-service";

function SignIn() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const toast = useToast();

  const [isLowerCaseValid, setIsLowerCaseValid] = useState(false);
  const [isUpperCaseValid, setIsUpperCaseValid] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);
  const [isMinLengthValid, setIsMinLengthValid] = useState(false);
  const [isNoLeadingTrailingSpaceValid, setIsNoLeadingTrailingSpaceValid] = useState(false);
  const [isPasswordMatched, setIsPasswordMatched] = useState(false);
  const [smartfinancePlan, setSmartfinancePlan] = useState('SMARTFINANCE_LITE_MONTHLY');
  const [smarttaxPlan, setSmarttaxPlan] = useState('');

  // const useQuery = () => {
  //   return new URLSearchParams(window.location.search);
  // }
  // const query = useQuery();
  // const queryPlan = query.get("plan");
  //
  // useEffect(() => {
  //   if (queryPlan) {
  //     setPlan(queryPlan);
  //   }
  // }, [queryPlan]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLowerCaseValid(/[a-z]/g.test(password));
    setIsUpperCaseValid(/[A-Z]/g.test(password));
    setIsNumberValid(/[0-9]/g.test(password));
    setIsSpecialCharValid(/[!@#$%^&*()\-_.+=[\]{}|\\:;,.<>?/]/g.test(password));
    setIsMinLengthValid(password.length >= 8);
    setIsNoLeadingTrailingSpaceValid(password.trim() === password);
    setIsPasswordMatched(password === confirmPassword);
  }, [password, confirmPassword]);

  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const [show, setShow] = React.useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [planUrl, setPlanUrl] = useState('');

  const handleClick = () => setShow(!show);

  const handlePhoneNumberChange = (e) => {
    const {value} = e.target;
    let onlyNums = value.replace(/\D/g, '');
    let formattedNumber;
    if (onlyNums.length <= 3) {
      formattedNumber = onlyNums;
    } else if (onlyNums.length <= 6) {
      formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
    } else {
      formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    }

    setPhoneNumber(formattedNumber);
  };

  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }

    let errorMessage = '';

    if (firstName === '' || lastName === '') {
      errorMessage = 'Please input name.';
    } else if (email === '') {
      errorMessage = 'Please input valid email.';
    } else if (phoneNumber && !isValidUSPhone(phoneNumber)) {
      errorMessage = 'Please input valid mobile phone number.';
    } else if (companyName === '') {
      errorMessage = 'Please input company name.';
    } else if (smartfinancePlan === '' && smarttaxPlan === '') {
      errorMessage = 'You must select at least one subscription plan.';
    } else if (password === '') {
      errorMessage = 'You must enter a password.';
    } else if (password !== confirmPassword) {
      errorMessage = "Passwords don't match.";
    } else if (!isTermsAgreed) {
      errorMessage = 'Please agree to the Terms of Service and Privacy Policy before proceeding.';
    }

    if (errorMessage) {
      setError(errorMessage);
      toast({
        title: 'Error', description: errorMessage, status: 'error', duration: 5000, isClosable: true,
      });
      return;
    }

    try {
      setIsUpdating(true);

      const {url} = await createAdvisor({
        firstName, lastName, email: email.trim().toLowerCase(), ...(phoneNumber ? {
          phoneNumber: `+1${phoneNumber.replace(/\D/g, '')}`
        } : {}), fullName: companyName, password, accountType: 'advisor', smartfinancePlan, smarttaxPlan,
      });

      setPlanUrl(url);
      onOpen();

      // await signup({
      //   firstName,
      //   lastName,
      //   email,
      //   phoneNumber: `+1${phoneNumberDigits}`,
      //   fullName: companyName,
      //   password,
      //   accountType: 'advisor'
      // });

      //
      // toast({
      //   title: 'Check your email',
      //   description: `We have sent a verification link to your email.`,
      //   status: 'success',
      //   duration: 9000,
      //   isClosable: true,
      // });
      //
      // return history.push('/auth/login');
    } catch (err) {
      console.log(err.message);
      toast({
        title: 'Error',
        description: err.response?.data.message || err?.message || 'There has been an error.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return setError(err.response?.data.message || err?.message || 'There has been an error.');
    } finally {
      setIsUpdating(false);
    }
  };

  const getCombinedPlanNames = () => {
    const smartfinancePlanName = getPlanName(smartfinancePlan);
    const smarttaxPlanName = getPlanName(smarttaxPlan);

    if (smartfinancePlan && smarttaxPlan) {
      return `smartfinance™ ${smartfinancePlanName} and smarttax™ ${smarttaxPlanName}`;
    } else if (smartfinancePlan) {
      return `smartfinance™ ${smartfinancePlanName}`;
    } else if (smarttaxPlan) {
      return `smarttax™ ${smarttaxPlanName}`;
    } else {
      return '';
    }
  };

  return (<DefaultAuth illustrationBackground={illustration} image={illustration}>
    <Flex
      maxW={{base: '100%', md: 'max-content'}}
      w="100%"
      mx={{base: 'auto', lg: '0px'}}
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{base: '30px', md: '60px'}}
      px={{base: '25px', md: '0px'}}
      flexDirection="column"
    >
      <VStack w="100%">
        <Heading color={textColor} fontSize="36px" mb="10px" textAlign="center">
          Register as an Advisor
        </Heading>
      </VStack>
      <Flex
        zIndex="2"
        direction="column"
        w={{base: '100%', md: '420px'}}
        maxW="100%"
        mt="16px"
        background="transparent"
        borderRadius="15px"
        mx={{base: 'auto', lg: 'unset'}}
        me="auto"
        mb={{base: '20px', md: 'auto'}}
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{base: '100%', md: '420px'}}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{base: 'auto', lg: 'unset'}}
          me="auto"
          mb={{base: '20px', md: 'auto'}}
        >
          <h4
            style={{
              fontSize: '.9em', color: 'red', textAlign: 'center', fontWeight: 400, transition: '.2s all',
            }}
          >
            {error}
          </h4>
          <FormControl>
            <HStack
              mb="15px"
            >
              <Box>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                >
                  First Name<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{base: '0px', md: '0px'}}
                  placeholder="John"
                  fontWeight="500"
                  size="lg"
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                    setError(undefined);
                  }}
                />
              </Box>
              <Box>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                >
                  Last Name<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  value={lastName}
                  ms={{base: '0px', md: '0px'}}
                  placeholder="Doe"
                  fontWeight="500"
                  size="lg"
                  onChange={(event) => {
                    setLastName(event.target.value);
                    setError(undefined);
                  }}
                />
              </Box>
            </HStack>

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{base: '0px', md: '0px'}}
              type="email"
              placeholder="you@example.com"
              mb="15px"
              fontWeight="500"
              size="lg"
              onChange={(event) => {
                setEmail(event.target.value);
                setError(undefined);
              }}
            />

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              Mobile Phone Number
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              value={phoneNumber}
              ms={{base: '0px', md: '0px'}}
              type="tel"
              placeholder="(201)-555-0123"
              mb="15px"
              fontWeight="500"
              size="lg"
              onChange={(event) => {
                handlePhoneNumberChange(event);
                setError(undefined);
              }}
            />

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              Company<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              value={companyName}
              ms={{base: '0px', md: '0px'}}
              type="text"
              placeholder="Company Name"
              mb="15px"
              fontWeight="500"
              size="lg"
              onChange={(event) => {
                setCompanyName(event.target.value);
                setError(undefined);
              }}
            />

            <FormLabel
              display="flex"
              variant="auth"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              Plan Type<Text color={brandStars}>*</Text>
            </FormLabel>
            <Radio colorScheme="brandScheme" defaultChecked fontSize='sm'>Customization Fee - $499 (One Time)</Radio>
            <Box mt='8px'>
              <FormLabel ms="4px" color={brandStars}>Smartfinance</FormLabel>
              <RadioGroup value={smartfinancePlan} onChange={(e) => setSmartfinancePlan(e)}>
                <VStack align='start'>
                  <Radio colorScheme="brandScheme" fontSize='sm'
                         value='SMARTFINANCE_LITE_MONTHLY'>Lite - $400 (Monthly)</Radio>
                  <Radio colorScheme="brandScheme" fontSize='sm'
                         value='SMARTFINANCE_PRO_MONTHLY'>Pro - $700 (Monthly)</Radio>
                  <Radio colorScheme="brandScheme" fontSize='sm'
                         value='SMARTFINANCE_ELITE_MONTHLY'>Elite - $1,250 (Monthly)</Radio>
                  <Radio colorScheme="brandScheme" fontSize='sm'
                         value=''>None</Radio>
                </VStack>
              </RadioGroup>
            </Box>

            <Divider mt='8px' />

            <Box>
              <FormLabel ms="4px" mt='8px' color={brandStars}>Smarttax</FormLabel>
              <RadioGroup value={smarttaxPlan} onChange={(e) => setSmarttaxPlan(e)}>
                <VStack align='start'>
                  <Radio colorScheme="brandScheme" fontSize='sm'
                         value='SMARTTAX_LITE_MONTHLY'>Lite - $1,250 (Monthly)</Radio>
                  <Radio colorScheme="brandScheme" fontSize='sm'
                         value='SMARTTAX_PRO_MONTHLY'>Pro - $2,200 (Monthly)</Radio>
                  <Radio colorScheme="brandScheme" fontSize='sm'
                         value='SMARTTAX_ELITE_MONTHLY'>Elite - $5,000 (Monthly)</Radio>
                  <Radio colorScheme="brandScheme" fontSize='sm'
                         value=''>None</Radio>
                </VStack>
              </RadioGroup>
            </Box>

            <FormLabel
              ms="4px"
              mt='8px'
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="15px"
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
                onChange={(event) => {
                  setPassword(event.target.value);
                  setError(undefined);
                }}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{cursor: 'pointer'}}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>

            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Confirm Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="15px"
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
                value={confirmPassword}
                onChange={(event) => {
                  setConfirmPassword(event.target.value);
                  setError(undefined);
                }}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{cursor: 'pointer'}}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>

            <Box mb={2}>
              <Text color={isLowerCaseValid ? 'green.500' : 'red.500'}>
                <Box as="span">{isLowerCaseValid ? '✓' : '✖'}</Box> Password must contain a lower case letter
              </Text>

              <Text color={isUpperCaseValid ? 'green.500' : 'red.500'}>
                <Box as="span">{isUpperCaseValid ? '✓' : '✖'}</Box> Password must contain an upper case letter
              </Text>

              <Text color={isNumberValid ? 'green.500' : 'red.500'}>
                <Box as="span">{isNumberValid ? '✓' : '✖'}</Box> Password must contain a number
              </Text>

              <Text color={isSpecialCharValid ? 'green.500' : 'red.500'}>
                <Box as="span">{isSpecialCharValid ? '✓' : '✖'}</Box> Password must contain a special character or a
                space
              </Text>

              <Text color={isMinLengthValid ? 'green.500' : 'red.500'}>
                <Box as="span">{isMinLengthValid ? '✓' : '✖'}</Box> Password must contain at least 8 characters
              </Text>

              <Text color={isNoLeadingTrailingSpaceValid ? 'green.500' : 'red.500'}>
                <Box as="span">{isNoLeadingTrailingSpaceValid ? '✓' : '✖'}</Box> Password must not contain a leading
                or trailing space
              </Text>

              {password && <Text color={isPasswordMatched ? 'green.500' : 'red.500'}>
                <Box as="span">{isPasswordMatched ? '✓' : '✖'}</Box> Password and Confirm Password should match
              </Text>}
            </Box>

            <Flex mb="4px">
              <Checkbox checked={isTermsAgreed} onChange={() => setIsTermsAgreed(prev => !prev)} me="16px"
                        colorScheme="brandScheme" />
              <Text
                color={textColor}
                fontSize="md"
                textAlign="start">
                By creating an account, you agree to our
                <Link
                  mx="3px"
                  color={brandStars}
                  href="/terms-of-service"
                  target="_blank">
                  Terms of Service
                </Link> and
                <Link
                  mx="3px"
                  color={brandStars}
                  href="/privacy-policy"
                  target="_blank">
                  Privacy Policy
                </Link>.
              </Text>
            </Flex>

            <Button
              disabled={!(!isUpdating && isLowerCaseValid && isUpperCaseValid && isNumberValid && isSpecialCharValid && isMinLengthValid && isNoLeadingTrailingSpaceValid && isPasswordMatched)}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="15px"
              onClick={register}
            >
              {isUpdating ? 'Signing up' : 'Sign up'}
            </Button>
          </FormControl>
          <VStack mb="20px">
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Already have account?
              <NavLink to="/auth/login">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Log In
                </Text>
              </NavLink>
            </Text>
          </VStack>
        </Flex>
      </Flex>
    </Flex>
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Registration Success!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize='lg'>1. Please check your email inbox to confirm your email. If it is not there, please check
            your spam folder.</Text>
          <Text fontSize='lg' mt={2}>2. Have you confirmed? Then, you're good to go. Please
            click <b>Confirm</b> button below to subscribe
            to <b>{getCombinedPlanNames()}</b> plan{smartfinancePlan && smarttaxPlan ? 's' : ''}.</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='brand' onClick={() => {
            window.location.href = planUrl;
          }}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </DefaultAuth>);
}

export default SignIn;
